


















































































































import "deep-chat";
import JnJuranewsSearch from "@/views/search/JuranewsSearch.vue";
import JnCardsStrip from "@/views/common/CardsStrip.vue";
import JnSearchResults from "@/views/search/SearchResults.vue";
import { Component, Vue } from "vue-property-decorator";
import { JnService } from "@/services/jn.service";
import { Testata } from "@/models/testata.model";
import { Sections } from "@/models/enums.model";
import MetaInfo from "vue-meta";
import { Conversation } from "@/models/ai/conversation.model";
import moment from "moment";
import { Response } from "deep-chat/dist/types/response";
import { DeepChat } from "deep-chat";
import { RequestDetails } from "deep-chat/dist/types/interceptors";


@Component({
  components: {
    JnJuranewsSearch,
    JnCardsStrip,
    JnSearchResults
  },
  metaInfo(this: JnAiChat): MetaInfo {
    return { title: "JuraNews - AI Chat" };
  },
})
export default class JnAiChat extends Vue {
  testata?: Testata;

  alert = false
  alertMessage = ""
  alertTimeout = 5000
  chatRequest = Vue.observable({
    "url": "http://localhost:6982/api/generator/chat",
    "method": "POST",
    "headers": { 
      "customName": "customHeaderValue" 
    },
    "additionalBodyProps": {
      "llm_model_name": process.env.VUE_APP_GENERATION_MODEL_NAME,
      "relevance_threshold": 0.3,
      "conversation_id": undefined
     },
  })
  avatars = {
    "default": {
      "styles": {
        "position": "left"
      }
    },
    "ai": {
      "src": "/img/ai-chat-bot.png"
    },
    "user": {
      "src": "/img/ai-chat-user.png"
    }
  }
  messageStyles={
    "default": {
      "shared": {
        "bubble": {
          "maxWidth": "95%",
          "backgroundColor": "unset",
          "width": '100%',
          "marginTop": "10px",
          "marginBottom": "10px"}},
      "user": {
        "bubble": {
          "marginLeft": "0px",
          "color": "black"}},
      "ai": {
        "outerContainer": {
          "backgroundColor": "rgba(247,247,248)",
          "borderTop": "1px solid rgba(0,0,0,.1)",
          "borderBottom": "1px solid rgba(0,0,0,.1)"
        }
      }
    }
  }
  textInput = {
    "styles": {
      "text": {
        "color": "black"
      },
      "container": {
        "maxHeight": "50px",
        "backgroundColor": "#f5f9ff"
      },
      "focus": {
        "border": "2px solid #e52024"
      }
    },
    "placeholder": {
      "text": "Scrivi qui la tua domanda", 
      "style": {
        "color": "#666666"
      }
    },
    "characterLimit": 100
  }
  htmlClassUtilities = {
    feedback: {
      styles: {
        default: {
          display: 'flex'
        }
      }
    },
    'feedback-text': {
      styles: {
        default: {
          width: 'calc(100% - 42px)', paddingTop: '2px'
        }
      }
    },
    'feedback-icon': {
      styles: {
        default: {width: '20px', height: '20px', cursor: 'pointer', borderRadius: '5px'},
        hover: {backgroundColor: '#d1d1d1'},
      },
    },
    'feedback-icon-positive': {
      events: {
        click: () => console.log('positive response')
      }
    },
    'feedback-icon-negative': {
      events: {
        click: () => console.log('negative response')
      },
      styles: {
        default: {
          transform: 'rotate(180deg)', marginLeft: '3px'
        }
      },
    },
  }

  conversations: Conversation[] = [];
  currentConversation?: Conversation;
  showConversations = true;

  toggleDrawer() {
      this.showConversations = !this.showConversations;
    }

  formatConversationDate(conversationDate: string) {
    return moment(conversationDate).format('DD-MM-YYYY HH:mm');
  }

  loadConversation(conversation: Conversation) {
    this.alertMessage = "Conversazione '" + conversation.title + "' caricata";
    this.alert = true;
    for (const conversationItem of this.conversations) {
      conversationItem.selected = false;
    }
    conversation.selected = true;
    this.currentConversation = conversation
  }

  newConversation() {
    this.currentConversation = undefined;
    this.chatRequest.additionalBodyProps.conversation_id = undefined;
    const chat = this.$refs.chat as DeepChat;
    chat.clearMessages();
  }

  requestInterceptor(message: RequestDetails): RequestDetails {
    console.log(message)
    if (this.currentConversation) {
      message.body.conversation_id = this.currentConversation.conversationId;
    }
    return message;
  }

  parseSources(sources: string[]): { id: number; source: string; section: string }[] {
    return sources.map(item => {
      const [idSource, titleSection] = item.split('§§§');
      const [id, source] = idSource.split('#');
      const titleSectionSplitted = titleSection.split('#')
      const title = titleSectionSplitted[0].split(':')[1].trim();
      const section = titleSectionSplitted[1].split(':')[1].trim();
      return {
        id: parseInt(id, 10),
        source,
        title: title,
        section: section
      };
    });
  }

  async responseInterceptor(message: any): Promise<Response> {
    console.log(message)
    if (!this.currentConversation) {
      const usernameFromCookie = String(this.$cookies.get("username"));
      this.currentConversation = new Conversation(
        usernameFromCookie,
        message.conversation_id)
    }
    const sources = this.parseSources(message.sources)
    console.log(sources)
    message.html = `<div class="feedback">
        <div class="feedback-text">` + message.text + `</div>
        <img class="feedback-icon feedback-icon-positive" src="/img/thumb-up.png">
        <img class="feedback-icon feedback-icon-negative" src="/img/thumb-down.png">
      </div>`
    message.text = undefined
    await this.updateConversations()
    return message;
  }

  async updateConversations() {
    const usernameFromCookie = this.$cookies.get("username")!;
    this.conversations = await JnService.getUserConversation(String(usernameFromCookie))
  }

  async created() {    
    this.testata = JnService.getTestata(Sections.CODICI);
    await this.updateConversations()
  }
}

